import React from 'react';
import "./footer.css";
import { FiMail, FiGithub, FiLinkedin } from 'react-icons/fi';

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer__container container">
            <div className="footer__social">
                <a href="mailto:rohanshahsf@gmail.com" className="home__social-icon" target="_blank" rel="noreferrer">
                    <FiMail />
                </a>
                <a href="https://github.com/rohanshah35" className="home__social-icon" target="_blank" rel="noreferrer">
                    <FiGithub />
                </a>
                <a href="https://www.linkedin.com/in/rohanshah2/" className="home__social-icon" target="_blank" rel="noreferrer" >
                    <FiLinkedin />
                </a>    
            </div>
            <span className="footer__copy"></span>
        </div>
    </footer>
  );
}

export default Footer;