import React from 'react';
import { Cursor, useTypewriter } from 'react-simple-typewriter';

const Data = () => {
  const [text] = useTypewriter({
    words: ['ohan Shah'],
    typeSpeed: 150,
    deleteSpeed: 150,
    loop: {}
  });

  return (
    <div className="home__data">
        <h1 className="home__title">
            R{text}
            <Cursor cursorStyle='_' />
        </h1>
        <h3 className="home__subtitle">
            Student + Programmer
        </h3>
        <p className="home__description">
            From the Bay Area, California
        </p>
    </div>
  )
}

export default Data;