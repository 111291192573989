import React, { useState, useRef, useEffect, useCallback } from 'react';
import './projects.css';
import ergon from '../../assets/ergon.png';
import fidelityUtility from '../../assets/fidelityUtility.png';
import rmpSearch from '../../assets/rmpSearch.png';

const Projects = () => {
  const [mouseDownAt, setMouseDownAt] = useState(null);
  const [touchStartX, setTouchStartX] = useState(null);
  const [prevPercentage, setPrevPercentage] = useState(0);
  const [percentage, setPercentage] = useState(0);
  // const [progress, setProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const trackRef = useRef(null);
  const imageRefs = useRef([]);

  const handleMove = useCallback((clientX) => {
    if (mouseDownAt === null && touchStartX === null) return;

    const startX = mouseDownAt !== null ? mouseDownAt : touchStartX;
    const delta = startX - clientX;
    const maxDelta = trackRef.current.offsetWidth;
    const nextPercentage = Math.max(-100, Math.min(0, prevPercentage + (delta / maxDelta) * -100));

    if (Math.abs(startX - clientX) > 5) {
      setIsDragging(true);
    }

    setPercentage(nextPercentage);
    // setProgress(Math.abs(nextPercentage));
  }, [mouseDownAt, touchStartX, prevPercentage]);

  const handleEnd = useCallback(() => {
    setMouseDownAt(null);
    setTouchStartX(null);
    setPrevPercentage(percentage);
  }, [percentage]);

  useEffect(() => {
    const track = trackRef.current;
    const images = imageRefs.current;

    const handleMouseMove = (e) => handleMove(e.clientX);
    const handleTouchMove = (e) => {
      e.preventDefault();
      handleMove(e.touches[0].clientX);
    };

    if (mouseDownAt !== null || touchStartX !== null) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.addEventListener('mouseup', handleEnd);
      document.addEventListener('touchend', handleEnd);
    }

    const animation = { duration: 1200, fill: 'forwards' };
    track.animate({ transform: `translate(${percentage}%, -50%)` }, animation);
    images.forEach((image, index) => {
      if (image) {
        image.animate({ objectPosition: `${100 + percentage}% center` }, animation);
      }
    });

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchend', handleEnd);
    };
  }, [percentage, mouseDownAt, touchStartX, handleMove, handleEnd]);

  const handleImageClick = (index) => {
    if (!isDragging) {
      const projectUrls = [
        'https://github.com/ergon-app',
        'https://github.com/rohanshah35/fidelityUtility',
        'https://github.com/rohanshah35/RMP-Search-Tool',
      ];
      window.open(projectUrls[index], '_blank', 'noopener,noreferrer');
    }
    setIsDragging(false);
  };

  const handleWheel = useCallback((e) => {
    const scrollDelta = e.deltaY;
    const maxDelta = trackRef.current.offsetWidth;
    let nextPercentage = percentage - (scrollDelta / maxDelta) * 100;
    nextPercentage = Math.max(-100, Math.min(0, nextPercentage));

    setPercentage(nextPercentage);
    setPrevPercentage(nextPercentage);
    // setProgress(Math.abs(nextPercentage));

    e.preventDefault();
  }, [percentage]);

  useEffect(() => {
    const track = trackRef.current;
    track.addEventListener('wheel', handleWheel);
    return () => track.removeEventListener('wheel', handleWheel);
  }, [handleWheel]);

  const projects = [
    {
      image: ergon,
      title: 'Ergon',
      description: 'Developed a full-stack web app for storing class notes, automatically generating summaries, flashcards, and study guides. Implemented a custom REST API with full CRUD operations, utilizing AWS S3 for file storage and OpenAI for content generation. Built an interactive, responsive front-end with React and modern CSS.'
    },
    {
      image: fidelityUtility,
      title: 'Fidelity Utility',
      description: "This Python application serves as a mock API for Fidelity using Selenium and BeautifulSoup, allowing users to retrieve personal portfolio information as well as general fund and stock data. It also includes scripts for various portfolio metrics."
    },
    {
      image: rmpSearch,
      title: 'RMP Search Tool',
      description: 'This Java application conducts sentiment analysis on professor ratings scraped from RateMyProfessor using Selenium. It features multiple data visualization screens to assist prospective students in evaluating professors.'
    },
  ];

  if (imageRefs.current.length !== projects.length) {
    imageRefs.current = Array(projects.length).fill(null);
  }

  return (
    <div id="projects" className="projects">
      <div
        id="image-track"
        ref={trackRef}
        onMouseDown={(e) => {
          e.preventDefault();
          setMouseDownAt(e.clientX);
          setIsDragging(false);
        }}
        onTouchStart={(e) => {
          e.preventDefault();
          setTouchStartX(e.touches[0].clientX);
          setIsDragging(false);
        }}
        style={{ transform: `translate(${percentage}%, -50%)` }}
      >
        {projects.map((project, index) => (
          <div key={index} className="image-container">
            <img
              ref={(el) => {
                imageRefs.current[index] = el;
              }}
              className="image"
              src={project.image}
              draggable="false"
              alt={project.title}
              onClick={() => handleImageClick(index)}
            />
            <div className="image-overlay">
              <p>{project.title}</p>
              <p>{project.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
